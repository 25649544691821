<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <!--      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="100px"
          max-width="200px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

&lt;!&ndash;<h2 class="text&#45;&#45;primary">
          {{ appName }}
        </h2>&ndash;&gt;
      </router-link>-->
      <!--/ brand logo -->

      <v-row class="auth-row ma-0 align-center justify-center">
        <v-col
          md="6"
          lg="7"
          xl="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-2.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-0 pe-0">
              <v-img
                contain
                max-width="100%"
                height="710"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/illustration-register-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          md="6"
          lg="5"
          xl="4"
          class="d-flex align-center auth-bg pa-10 pa-md-0 pa-md-0 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-title class="d-flex align-center justify-center py-7">
                  <router-link
                    to="/"
                    class="d-flex align-center"
                  >
                    <v-img
                      :src="appLogo"
                      max-height="90px"
                      alt="logo"
                      contain
                      class="me-3 "
                    ></v-img>
                  </router-link>
                </v-card-title>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2 text-center">
                    {{ $t("Adventure starts here") }} 🚀
                  </p>
                  <p class="mb-2 text-center">
                    {{ $t("Make your logistic management easy and fun!") }}
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="registerForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-row>
                      <v-col md="6">
                        <v-text-field
                          v-model="name"
                          outlined
                          :label="$t('Display name')"
                          :placeholder="$t('Display name')"
                          :error-messages="errorMessages.name"
                          :rules="[validators.required]"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="firstname"
                          outlined
                          :label="$t('First name')"
                          :placeholder="$t('First name')"
                          :error-messages="errorMessages.firstname"
                          :rules="[validators.required]"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="lastname"
                          outlined
                          :label="$t('Last name')"
                          :placeholder="$t('Last name')"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="phone"
                          outlined
                          :label="$t('Phone')"
                          :placeholder="$t('Phone')"
                          :error-messages="errorMessages.phone"
                          :rules="[validators.required]"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="company"
                          outlined
                          :label="$t('Company')"
                          :placeholder="$t('Company')"
                          :error-messages="errorMessages.company"
                          :rules="[validators.required]"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="website"
                          outlined
                          :label="$t('Website')"
                          :placeholder="$t('Website')"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="email"
                          outlined
                          :error-messages="errorMessages.email"
                          :rules="[validators.required, validators.emailValidator]"
                          label="Email"
                          placeholder="Email"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          label="Password"
                          :error-messages="errorMessages.password"
                          placeholder="Password"
                          :rules="[validators.required, validators.passwordValidator]"
                          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                          hide-details="auto"
                          @click:append="isPasswordVisible = !isPasswordVisible"
                        ></v-text-field>
                      </v-col>
                      <v-col md="12">
                        <v-autocomplete
                          v-model="services"
                          outlined
                          multiple
                          :label="$t('Service')"
                          :placeholder="$t('Service')"
                          hide-details="auto"
                          :items="ourServices"
                          item-value="id"
                          item-text="label"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="12">

                        <v-checkbox
                          :rules="[validators.required]"
                          hide-details
                          class="mt-0"
                        >
                          <template #label>
                            <div class="d-flex align-center flex-wrap">
                              <span class="me-2">{{ $t("I agree to") }}</span><a href="javascript:void(0)">{{ $t("privacy policy") }} &amp;
                                {{ $t("terms") }}</a>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-btn
                      :loading="isLoading"
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      {{ $t("Sign Up") }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    {{ $t("Already have an account ?") }}
                  </p>
                  <router-link :to="{name:'auth-login'}">
                    {{ $t("Sign in instead") }}
                  </router-link>
                </v-card-text>

                <!-- divider -->
                <!--
                <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text>
-->

                <!-- social links -->
                <!--                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>-->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { required, emailValidator, passwordValidator, alphaValidator } from '@core/utils/validation'
import { ref, getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import ability from '@/plugins/acl/ability'
import store from '@/store'

export default {
  setup() {
    // Template Refs
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const isLoading = ref(false)
    const isPasswordVisible = ref(false)
    const username = ref('johnDoe')
    const firstname = ref('')
    const lastname = ref('')
    const name = ref('')
    const company = ref('')
    const website = ref('')
    const phone = ref('')
    const email = ref('')
    const password = ref('')
    const services = ref([])
    const ourServices = ref([
      {
        id: 'restaurant',
        label: 'Restaurant',
      },
      {
        id: 'shop',
        label: 'Restaurant',
      },
      {
        id: 'ecommerce',
        label: 'Ecommerce',
      },
    ])
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken (Register the user & login) (User will be added to the database and new generated access token will be sent for loggin in)
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */
      isLoading.value = true

      axios({
        method: 'post',
        url: '/register',
        data: {
          name: name.value,
          email: email.value,
          password: password.value,
          firstname: firstname.value,
          lastname: lastname.value,
          company: company.value,
          website: website.value,
          phone: phone.value,
          services: services.value,
        },
        // eslint-disable-next-line no-unused-vars
        validateStatus: status => true,
      }).catch(error => {
        // this.loading = false
        isLoading.value = false
        console.log(error)
        // eslint-disable-next-line consistent-return
      }).then(response => {
        if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
          if (response.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...response.data.errors }
          } else {
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = response.data.message

            // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
          }

          return false
        }
        if (response.data && response.data.id) {
          /* this.$store.set('user/login', true)
          this.$store.set('user/user', response.data)
          this.$router.push('/') */
          isLoading.value = false

          // store.dispatch('app/setUser', response.data)

          const user = response.data

          const userAbility = user.permissions.map(item => {
            const actionResource = item.split('-')
            if (actionResource.length > 0) {
              const action = actionResource[0]
              const subject = actionResource[1]

              return { action, subject }
            }

            return { action: item, subject: item }
          })
          userAbility.push({ action: 'read', subject: 'Public' })

          // userAbility.push({ action: 'all', subject: 'Manage' })

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.permissions
          store.state.app.user = user

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(user))
          router.push('/')

          /* // const { user } = response.data
          const user = {
            ...response.data,
            role: 'admin',
            avatar: require('@/assets/images/avatars/1.png'),
            fullName: response.data.name,
            permissions: [/!* 'edit-article', 'view-role', *!/'read-ACL', 'read-Public', 'manage-all'],
          }

          const userAbility = user.permissions.map(item => {
            const actionResource = item.split('-')
            if (actionResource.length > 0) {
              const action = actionResource[0]
              const subject = actionResource[1]

              return { action, subject }
            }

            return { action: item, subject: item }
          })

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.permissions

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(user)) */
        }
      }).then(() => {
        isLoading.value = false
      })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error)

          /* errorMessages.value = error.response.data.error */
        })
    }

    return {
      isSnackbarBottomVisible,
      snackBarMessage,
      isLoading,
      isPasswordVisible,
      username,
      email,
      firstname,
      lastname,
      phone,
      website,
      services,
      ourServices,
      name,
      company,
      password,
      errorMessages,
      handleFormSubmit,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
